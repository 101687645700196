import React from 'react'
import cancel from '../../assets/img/cancel.svg'
import BigButton from '../button/BigButton'

function PromotionPopup({close}) {
  return (
    <div className="modalWarpper model-transition">
      <div className="modalBackdrop" onClick={close}></div>
      <div className="modalBox2 h-auto d-flex">
        <div className='model-content webinar-event d-flex w-100 flex-column  p-2'>
          <div className='modal-header text-center  border-0 px-xl-2'>
            <h5 className='modal-title  fw-medium'>Upcoming Event</h5>
            <img src={cancel} className="popup_btn-cancel" onClick={close} />
          </div>
          <div className='row g-3 g-xxl-4 flex-column flex-lg-row '>
            <div className='col'>
              <div className='card h-100  border-0 cloud'>
                <div className='card-body p-0 m-0 '>
                  <h5 className='join-us text-center text-white'>JOIN US AT</h5>
                  <div className='d-lg-none row pb-3 text-center'>
                    <div className='col-12 me-2 my-auto'>
                      <h3 className='card-title text-white mb-0 pe-2 pb-0'>
                      Mastering Cards <br/> System Migration
                      </h3>

                    </div>
                    <div className='col-12 my-auto'>
                    <h4 className='event d-lg-none'></h4>

                    </div>
                    <div className='col-12  my-auto'>
                    <h3 className='card-text text-white mb-0 pb-0'>Sept 19<sup>th</sup> at 3PM IST</h3>
                    </div>
                  </div>
                  <h2 className=' text-white text-center mb-0 pb-lg-0 fw-bold d-none d-lg-block'>Mastering Cards System Migration</h2>
                  <h4 className='event d-none d-lg-block'></h4>
                  <h3 className='card-text text-center text-white fw-light mb-3 mb-xl-2 d-none d-lg-block'>
                    Sept 19<sup>th</sup> at 3PM IST
                  </h3>
                  <div className='text-center'>
                    <BigButton
                    btnText='Register Now'
                    btnClass='light'
                    link='/mastering-cards-system-migration/'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
      </div>
  )
}

export default PromotionPopup